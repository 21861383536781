import React from "react";
import { Button, ButtonProps } from "@mui/material";

interface RoundedButtonProps extends ButtonProps {}

const roundedButtonStyles = {
  borderRadius: "50px",
  padding: "10px 20px",
  fontSize: "20px",
  backgroundColor: "#069AF3",
  color: "white",
  border: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#057ACC", // Adjust hover color if needed
  },
};

const RoundedButton: React.FC<RoundedButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button style={roundedButtonStyles} {...props}>
      {children}
    </Button>
  );
};

export default RoundedButton;

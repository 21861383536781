"use client";

import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import {
  Divider,
  alpha,
  styled,
  Box,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Subscribe } from "./Subscribe";
import Logo from "../../public/Logo.png";
import RoundedButton from "./RoundedButton";
import styles from "./page.module.css";

const HeaderStyled = styled("header")`
  align-items: center;
  background-color: black;
  display: flex;
  gap: 8px;
  padding: 16px;
`;

const NavLinkStyled = styled(Link)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  transition: 300ms ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    color: ${({ theme }) => alpha(theme.palette.common.white, 0.7)};
  }
`;

const StyledPopoverContent = styled(Box)`
  border-radius: 20px;
  left: 50%;
  padding: 16px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;

  @media (max-width: 786px) {
    padding: 0 10px; /* Add margin on mobile */
    width: 90%;
  }
`;

const Nav = styled("nav")`
  align-items: center;
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

const LogoImage = styled(Image)`
  height: 40px; /* Adjust the height as needed */
  cursor: pointer;
  margin-right: 8px; /* Adjust the margin as needed */
`;

export const Header = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <HeaderStyled>
      <NavLinkStyled href="/" passHref>
        <LogoImage src={Logo} alt="Home" width={40} height={40} />
        <span className={styles["hide-on-phone"]}> Nikola Knezevic</span>
      </NavLinkStyled>

      <Nav>
        <NavLinkStyled href="/blogs" passHref>
          Blogs
        </NavLinkStyled>

        <Divider
          flexItem
          variant="middle"
          orientation="vertical"
          className="hide-on-mobile"
        />

        <RoundedButton
          color="primary"
          variant="contained"
          disableElevation
          disableRipple
          onClick={handleOpen}
        >
          <Typography>Subscribe</Typography>
        </RoundedButton>

        <Modal open={open} onClose={handleClose}>
          <StyledPopoverContent bgcolor="background.paper">
            <Box display="flex" justifyContent="flex-end">
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box mb={2} />

            <Subscribe
              titleSize={20}
              textSize={16}
              inputSize={42}
              gap={16}
              title="Level Up Your .NET Skills"
              text="Stay tuned for valuable insights each week!"
              onSubscribed={handleClose}
              inputWidth={250}
            />

            <Box mb={3} />
          </StyledPopoverContent>
        </Modal>
      </Nav>
      <style jsx>{`
        .headerStyled {
          align-items: center;
          background-color: black;
          display: flex;
          gap: 8px;
          padding: 16px;
        }

        .navLinkStyled {
          color: white;
          font-size: 24px;
          font-weight: bold;
          text-decoration: none;
          transition: 300ms ease;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .navLinkStyled:hover {
          color: ${alpha("#fff", 0.7)};
        }

        .popupContent {
          border-radius: 20px;
          left: 50%;
          padding: 16px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 400px;
        }

        @media (max-width: 786px) {
          .popupContent {
            padding: 0 10px; /* Add margin on mobile */
            width: 90%;
          }
        }

        .nav {
          align-items: center;
          display: flex;
          gap: 16px;
          margin-left: auto;
        }

        .logoImage {
          height: 40px; /* Adjust the height as needed */
          cursor: pointer;
          margin-right: 8px; /* Adjust the margin as needed */
        }

        .roundedButton {
          border-radius: 50px;
          padding: 10px 20px;
          font-size: 20px;
          background: #069af3;
          color: white;
          border: none;
          cursor: pointer;
        }
      `}</style>
    </HeaderStyled>
  );
};

export default Header;

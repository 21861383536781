"use client";
import { FC, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import { SiMinutemailer } from "react-icons/si";
import { MdMarkEmailRead } from "react-icons/md";
import { Box, Typography, styled } from "@mui/material";
import { emailRegExp } from "../utils/emailValidationRegex";

const SubscribeWrapper = styled(Box)<Pick<SubscribeProps, "gap">>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ gap }) => `${gap}px`};
`;

const SubscribeText = styled(Typography)`
  max-width: 720px;
  text-align: center;
`;

const SubscribeFieldWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0px 0px 0px 2px rgba(0.2, 0.2, 0.2, 0);
`;

type InputFieldSize = Pick<SubscribeProps, "inputSize">;

const SubscribeField = styled("input", {
  shouldForwardProp: (prop) => prop !== "inputSize",
})<InputFieldSize>`
  background: #ffffff;
  border-radius: 4px 0 0 4px;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  font-size: ${({ inputSize }) => `${inputSize! * 0.3333}px`};
  height: ${({ inputSize }) => `${inputSize}px`};
  padding: 0 16px;
  width: calc(100% - ${({ inputSize }) => `${inputSize}px`});

  &:hover {
    background: #ffffff;
  }

  &:focus {
    outline: none;
    background: #ffffff;
  }
`;

const SubscribeBtn = styled("button", {
  shouldForwardProp: (prop) => prop !== "inputSize",
})<InputFieldSize>`
  position: relative;
  height: ${({ inputSize }) => `${inputSize}px`};
  width: ${({ inputSize }) => `${inputSize}px`};
  border: none;
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 0 4px 4px 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }

  &.signed {
    .icon-notsigned {
      opacity: 0;
    }

    .icon-signed {
      opacity: 1;
    }
  }

  .icon-notsigned,
  .icon-signed {
    color: white;
    font-size: ${({ inputSize }) => `${inputSize! * 0.43}px`};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 300ms ease;
  }

  .icon-notsigned {
    opacity: 0.8;
  }

  .icon-signed {
    opacity: 0;
  }
`;

interface SubscribeProps {
  title?: string;
  text?: string;
  titleSize?: number;
  textSize?: number;
  gap?: number;
  inputWidth?: number | string;
  inputSize?: number;
  onSubscribed?: () => void;
}

export const Subscribe: FC<SubscribeProps> = ({
  text,
  title,
  inputSize = 60,
  gap = 32,
  titleSize = 32,
  inputWidth = 350,
  textSize = 16,
  onSubscribed,
}) => {
  const [subscribing, setSubscribing] = useState<
    "pending" | "subscribing" | "signed"
  >("pending");

  const [subscribersEmail, setSubscribersEmail] = useState("");

  const onSubscribeClickHandler = async () => {
    if (!subscribersEmail.match(emailRegExp)) {
      return toast.error("Email you provided is not valid.");
    }

    setSubscribing("subscribing");

    try {
      const response = await fetch("/api/addSubscriber", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: subscribersEmail }),
      });

      if (!response.ok) {
        const error = await response.json();
        toast.error(error.error);
        setSubscribing("pending");
        setSubscribersEmail("");
        return;
      }

      toast.success("You have successfully subscribed to our newsletter.");
      setSubscribing("signed");
      onSubscribed?.();

      setTimeout(() => {
        setSubscribing("pending");
      }, 1800);
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
      setSubscribing("pending");
    }
  };

  return (
    <SubscribeWrapper gap={gap}>
      {title && (
        <Typography
          component="h1"
          variant="h2"
          fontSize={titleSize}
          color={"black"}
          sx={{ fontWeight: "bold" }}
        >
          {title}
        </Typography>
      )}

      {text && (
        <SubscribeText variant="subtitle1" color={"black"} fontSize={textSize}>
          {text}
        </SubscribeText>
      )}

      <SubscribeFieldWrapper maxWidth={inputWidth}>
        <SubscribeField
          value={subscribersEmail}
          onChange={(e: { target: { value: SetStateAction<string> } }) =>
            setSubscribersEmail(e.target.value)
          }
          placeholder="Your email"
          aria-invalid={subscribersEmail.match(emailRegExp) ? "true" : "false"}
          inputSize={inputSize}
        />

        <SubscribeBtn
          className={subscribing}
          disabled={subscribing !== "pending" || !subscribersEmail.length}
          onClick={onSubscribeClickHandler}
          inputSize={inputSize}
        >
          <MdMarkEmailRead className="icon icon-signed" />
          <SiMinutemailer className="icon icon-notsigned" />
        </SubscribeBtn>
      </SubscribeFieldWrapper>
    </SubscribeWrapper>
  );
};
